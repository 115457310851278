import { visualization } from "@fscrypto/domain";
import { ScatterInputs } from "@fscrypto/domain/visualization/v3";
import type { Options } from "highcharts";
import * as R from "remeda";
import { groupData } from "../../data";
import { XYChartBuilder } from "./xy-chart";

export class ScatterOptionsBuilder extends XYChartBuilder<ScatterInputs> {
  type = "scatter" as const;
  constructor(input: ScatterInputs, options: Options) {
    super(input, options);
  }
  getHighchartsType() {
    return "bubble" as const;
  }

  public getData(data: Record<string, unknown>[]) {
    const { x, y, grouping } = this.inputs.config;
    if (!x || !y) return data;
    let transformedData: any[] = data;
    if (grouping) {
      transformedData = groupData(x.key, y[0].key, grouping.key, data);
    }
    const isReversed = (this.options.xAxis as Highcharts.XAxisOptions)?.reversed;
    if (visualization.v3.isDateType(x.type)) {
      transformedData = transformedData.map((d) => ({ ...d, [x.key]: new Date(d[x.key]).getTime() }));
      if (isReversed === undefined) return transformedData;
      const sortedAsc = R.sortBy(transformedData, (d) => d[x.key]);
      return isReversed ? sortedAsc.reverse() : sortedAsc;
    }
    return R.sortBy(transformedData, (x) => x.key);
  }
  public getSeries(data: any[]) {
    const { x, y, z, grouping } = this.inputs.config;
    if (!x || !y) return [];
    if (grouping) {
      const keys = R.pipe(
        data,
        R.flatMap((d) => Object.keys(d)),
        R.unique(),
        R.filter((key) => key !== x.key),
      ) as string[];

      return keys.map((key) => {
        return {
          type: "bubble" as const,
          data: data.map((d) => [d[x.key], Number(d[key]), z ? Number(d[z.key]) : 1]),
          name: key,
          color: this.inputs.config.colors?.[key],
          maxSize: z ? undefined : 10,
        };
      });
    }
    return y.map(({ key, name }) => ({
      type: "bubble" as const,
      data: data.map((d) => [d[x.key], Number(d[key]), z ? Number(d[z.key]) : 1]),
      name: name ?? key,
      color: this.inputs.config.colors?.[key],
      maxSize: z ? undefined : 10,
    }));
  }
}
