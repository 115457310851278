import {
  Inputs,
  VisualizationType,
  isBigNumberInput,
  isCandlestick,
  isHeatmapInput,
  isHistogramInput,
  isPieInput,
  isTableInput,
  isXYInput,
} from "./schema";

type Requirement = {
  label: string;
  isMet: (inputs: Inputs) => boolean;
};

const xyRequirements: Requirement[] = [
  {
    label: "The X Axis requires a field to be chosen",
    isMet: (inputs) => isXYInput(inputs) && !!inputs.config?.x,
  },
  {
    label: "The Y Axis requires at least one field to be chosen",
    isMet: (inputs) => isXYInput(inputs) && (inputs.config?.y ?? []).length > 0,
  },
];

const pieRequirements: Requirement[] = [
  {
    label: "The Pie requires a slice field value to be chosen",
    isMet: (inputs) => isPieInput(inputs) && !!inputs.config?.slice,
  },
  {
    label: "The Pie requires a value field to be chosen",
    isMet: (inputs) => isPieInput(inputs) && !!inputs.config?.value,
  },
];

const chartRequirements: Record<VisualizationType, Requirement[]> = {
  bar: xyRequirements,
  "bar-stacked": xyRequirements,
  "bar-line": xyRequirements,
  "bar-normalized": xyRequirements,
  "bar-horizontal": xyRequirements,
  "bar-horizontal-stacked": xyRequirements,
  "bar-horizontal-normalized": xyRequirements,
  line: xyRequirements,
  area: xyRequirements,
  "area-stacked": xyRequirements,
  "area-normalized": xyRequirements,
  scatter: xyRequirements,
  pie: pieRequirements,
  "viz-table": [
    {
      label: "At least one column should be selected",
      isMet: (inputs) => {
        return (
          isTableInput(inputs) && Object.values(inputs.config.columnOptions).some((option) => option.visible === true)
        );
      },
    },
  ],
  "big-number": [
    {
      label: "The Primary Number Field should be chosen",
      isMet: (inputs) => {
        return isBigNumberInput(inputs) && !!inputs.config?.valueKey;
      },
    },
  ],
  histogram: [
    {
      label: "The value field should be chosen",
      isMet: (inputs) => {
        return isHistogramInput(inputs) && !!inputs.config?.y?.[0]?.key;
      },
    },
  ],
  heatmap: [
    {
      label: "The X Axis requires a field to be chosen",
      isMet: (inputs) => isHeatmapInput(inputs) && !!inputs.config.x,
    },
    {
      label: "The Y Axis requires a field to be chosen",
      isMet: (inputs) => isHeatmapInput(inputs) && !!inputs.config?.y,
    },
    {
      label: "The value field should be chosen",
      isMet: (inputs) => {
        return isHeatmapInput(inputs) && !!inputs.config?.value;
      },
    },
  ],
  candlestick: [
    {
      label: "The X Axis requires a field to be chosen",
      isMet: (inputs) => isCandlestick(inputs) && !!inputs.config?.x,
    },
    {
      label: "Open requires a field to be chosen",
      isMet: (inputs) => isCandlestick(inputs) && !!inputs.config?.open,
    },
    {
      label: "High requires a field to be chosen",
      isMet: (inputs) => isCandlestick(inputs) && !!inputs.config?.high,
    },
    {
      label: "Low requires a field to be chosen",
      isMet: (inputs) => isCandlestick(inputs) && !!inputs.config?.low,
    },
    {
      label: "Close requires a field to be chosen",
      isMet: (inputs) => isCandlestick(inputs) && !!inputs.config?.close,
    },
  ],
};

export const visualizationTypeLabels: Record<VisualizationType, string> = {
  bar: "Bar Chart",
  "bar-stacked": "Stacked Bar Chart",
  "bar-normalized": "Normalized Bar Chart",
  "bar-horizontal": "Horizontal Bar Chart",
  "bar-horizontal-stacked": "Horizontal Stacked Bar Chart",
  "bar-horizontal-normalized": "Horizontal Normalized Bar Chart",
  line: "Line Chart",
  area: "Area Chart",
  "area-stacked": "Stacked Area Chart",
  "area-normalized": "Normalized Area Chart",
  scatter: "Scatter Plot",
  "bar-line": "Bar-Line Chart",
  pie: "Pie Chart",
  "viz-table": "Table",
  "big-number": "Big Number",
  histogram: "Histogram",
  heatmap: "Heatmap",
  candlestick: "Candlestick",
};

export const hasAllRequiredFields = (
  input: Inputs,
): { areRequirementsMet: boolean; requirementsStatus: RequirementsStatus } => {
  const requirements = chartRequirements[input.type] || [];

  const requirementsStatus = requirements.map((req) => ({
    message: req.label,
    met: req.isMet(input),
  }));

  const areRequirementsMet = requirementsStatus.every((status) => status.met);

  return { areRequirementsMet, requirementsStatus };
};

export type RequirementsStatus = { message: string; met: boolean }[];
