import { type HeatmapInputs } from "@fscrypto/domain/visualization/v3";
import * as R from "remeda";
import { smallerTitleMargin } from "../util";
import { XYChartBuilder } from "./xy-chart";

type InternalData = {
  data: { x: number; y: number; value: number }[];
  xCategories: string[];
  yCategories: string[];
};

export class HeatmapOptionsBuilder extends XYChartBuilder<HeatmapInputs> {
  type = "heatmap" as const;
  constructor(input: HeatmapInputs, config: Highcharts.Options) {
    super(input, config);
  }

  getHighchartsType() {
    return "heatmap" as const;
  }

  getData(data: Record<string, unknown>[]): [InternalData] {
    const { x, y, value } = this.inputs.config;
    if (!x || !y?.length || !value)
      return [{ data: data as { x: number; y: number; value: number }[], xCategories: [], yCategories: [] }];
    const xCategories = R.unique(data.map((d) => d[x.key] as string));
    const yCategories = R.unique(data.map((d) => d[y[0].key] as string));
    const mappedData = xCategories.flatMap((xCategory, xIndex) =>
      yCategories.map((yCategory, yIndex) => ({
        x: xIndex,
        y: yIndex,
        value: R.sum(
          data.filter((d) => d[x.key] === xCategory && d[y[0].key] === yCategory).map((d) => d[value.key] as number),
        ),
      })),
    );

    return [
      {
        data: mappedData,
        xCategories,
        yCategories,
      },
    ];
  }

  getSeries([{ data }]: [InternalData]) {
    return [{ type: this.getHighchartsType(), data }];
  }

  themedOptions(theme: "light" | "dark") {
    return {
      colorAxis: {
        labels: theme === "dark" ? { style: { color: "#fff" } } : {},
      },
    };
  }

  build(internalData: Record<string, unknown>[]) {
    const [{ xCategories, yCategories }] = internalData as [InternalData];
    const series = this.getSeries(internalData as [InternalData]);
    return R.mergeDeep(
      {
        series,
        title: smallerTitleMargin(this.options.title),
        colorAxis: {
          minColor: this.inputs.config.colors?.["minColor"],
          maxColor: this.inputs.config.colors?.["maxColor"],
          reversed: false,
          width: this.options.legend?.layout === "horizontal" || !this.options.legend?.layout ? "95%" : undefined,
          height: this.options.legend?.layout === "vertical" ? "75%" : undefined,
        },
        xAxis: {
          categories: xCategories,
        },
        plotOptions: {
          heatmap: {
            dataLabels: {
              enabled: true,
              color: "#000000",
            },
          },
        },
      } satisfies Highcharts.Options,
      {
        ...this.options,
        yAxis: [
          R.mergeDeep(
            {
              categories: yCategories,
              title: {
                text: this.inputs.config.y?.[0].name,
              },
              opposite: this.inputs.config.y?.[0].position === "right",
            },
            ((this.options.yAxis as Highcharts.YAxisOptions[] | undefined)?.[0] ?? {}) as Record<string, unknown>,
          ),
        ],
      },
    ) as Highcharts.Options;
  }
}
