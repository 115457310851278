import { PieInputs } from "@fscrypto/domain/visualization/v3";
import * as R from "remeda";
import { categorical } from "../colors/default";
import { groupData } from "../data";
import { smallerTitleMargin } from "./util";

export class PieOptionsBuilder {
  constructor(
    public readonly inputs: PieInputs,
    public readonly options: Highcharts.Options = {},
  ) {}

  build(data: Record<string, unknown>[]): Highcharts.Options {
    const series = this.getSeries(data);
    if (!series) return { ...this.options };
    return {
      ...this.options,
      title: smallerTitleMargin(this.options.title),
      series: [series],
      plotOptions: {
        area: {},
      },
      colors: this.options.colors ?? categorical,
    };
  }

  getData(data: Record<string, unknown>[]) {
    const { slice, value } = this.inputs.config;
    if (!slice || !value) return data;
    return groupData(slice.key, value.key, slice.key, data);
  }

  getDataKeys(data: any[]) {
    return R.pipe(data, R.flatMap(Object.keys), R.unique());
  }

  getSeries(data: any[]) {
    const { slice, value } = this.inputs.config;
    if (!slice || !value) return;
    console.log(this.inputs.config.colors, slice.key);
    return {
      type: "pie" as const,
      data: data.map((d) => ({
        name: d[slice.key],
        color: this.inputs.config.colors?.[d[slice.key]],
        y: Number(d[d[slice.key]]),
      })),
    };
  }
}
